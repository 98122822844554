<h1 mat-dialog-title>{{titleModal}}</h1>
<div mat-dialog-content>
    <form [formGroup]="folderForm">
        <div class="row">
            <div class="col-6">
                <mat-form-field appearance="outline">
                    <mat-label>Nom</mat-label>
                    <input formControlName="name" matInput placeholder="Nom du stagiaire">
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline">
                    <mat-label>Prénom</mat-label>
                    <input formControlName="surname" matInput placeholder="Prénom du stagiaire">
                </mat-form-field> 
            </div> 
        </div>
        

        <div class="row">
            <div class="col-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Type module</mat-label>
                    <mat-select formControlName="typeModule">
                         <mat-option>Choississez un module</mat-option>
                          <mat-option value="Module OCE">Module OCE</mat-option>
                          <mat-option value="Module AI">Module AI</mat-option>
                          <mat-option value="Module SRP">Module SRP</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
             <div class="col-6">
                 <mat-form-field appearance="outline">
                    <mat-label>État du dossier</mat-label>
                    <mat-select formControlName="stateFolder">
                         <mat-option>Choississez un état</mat-option>
                          <mat-option value="Dossier en attente">Dossier en attente</mat-option>
                          <mat-option value="Dossier actif">Dossier actif</mat-option>
                          <mat-option value="Dossier non actif">Dossier non actif</mat-option>
                    </mat-select>
                </mat-form-field>
             </div>
        </div>

        <div class="row">
            <div class="col-6">
                <mat-form-field appearance="outline">
                    <mat-label>Date d'entrée</mat-label>
                    <input  formControlName="dateStart" matInput [matDatepicker]="picker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
             </div>

            <div class="col-6">
                 <mat-form-field appearance="outline">
                    <mat-label>Date de fin</mat-label>
                    <input  formControlName="dateEnd" matInput [matDatepicker]="picker2">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
             </div>
        </div>
  
        <div class="row">
            <!--<div class="col-6">
                <mat-form-field appearance="outline">
                    <mat-label>DE - COORDONNÉES</mat-label>
                    <input
                        type="file"
                        class="absolute top-0 z-10 h-full w-full cursor-pointer opacity-0"
                        accept=".jpeg,.jpg,.png,.pdf"
                    />
                </mat-form-field>
            </div>-->
       
            <div class="col-6">
                <mat-form-field appearance="outline">
                    <mat-label>Commentaire:</mat-label>
                    <textarea formControlName="comment" matInput ></textarea>
                </mat-form-field>
             </div>
        </div>

        
    </form>
</div>
<div mat-dialog-actions [align]="'end'">
    <!--<button class="ml-8" mat-raised-button color="primary" (click)="addFolderNext()">{{addNextBtn}}</button>-->
    <button class="ml-8" mat-raised-button color="primary" (click)="addFolder()">{{actionBtn}}</button>
    <button mat-raised-button color="warn" mat-dialog-close>Fermer</button>
</div>