<h1 mat-dialog-title>{{titleModal}}</h1>
<div mat-dialog-content>
    <form [formGroup]="sheetForm">
         <mat-form-field appearance="outline">
                    <mat-label>Client</mat-label>
                    <mat-select formControlName="nomClient">
                        <mat-option>Choississez un client</mat-option>
                        <!--<mat-option  *ngFor="let customer of this.getAllUsers(); let i = index" value=""></mat-option>-->
                        <mat-option value="FOJ">FOJ</mat-option>
                        <mat-option value="Centre Espoir">Centre Espoir</mat-option>
                        <mat-option value="Sekoia Services SA">Sekoia Services SA</mat-option>
                        <mat-option value="Client inconnu">Client non renseigné</mat-option>
                    </mat-select>
                </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Classeur</mat-label>
            <input formControlName="nomClasseur" matInput placeholder="Nom du classeur">
        </mat-form-field>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Une année</mat-label>
                    <mat-select formControlName="year">
                        <mat-option>Choississez une année</mat-option>
                        <mat-option value="2022">2022</mat-option>
                        <mat-option value="2021">2021</mat-option>
                        <mat-option value="2020">2020</mat-option>
                        <mat-option value="2019">2019</mat-option>
                        <mat-option value="2018">2018</mat-option>
                        <mat-option value="2017">2017</mat-option>
                        <mat-option value="2016">2016</mat-option>
                        <mat-option value="2015">2015</mat-option>
                        <mat-option value="2014">2014</mat-option>
                        <mat-option value="2013">2013</mat-option>
                        <mat-option value="2012">2012</mat-option>
                        <mat-option value="2011">2011</mat-option>
                        <mat-option value="2010">2010</mat-option>
                        <mat-option value="2009">2009</mat-option>
                        <mat-option value="2008">2008</mat-option>
                        <mat-option value="2007">2007</mat-option>
                        <mat-option value="2006">2006</mat-option>
                        <mat-option value="2005">2005</mat-option>
                        <mat-option value="2004">2004</mat-option>
                        <mat-option value="2003">2003</mat-option>
                        <mat-option value="2002">2002</mat-option>
                        <mat-option value="2001">2001</mat-option>
                        <mat-option value="2000">2000</mat-option>
                        <mat-option value="Année inconnu">Année non renseigné</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Un mois</mat-label>
                    <mat-select formControlName="month">
                        <mat-option>Choississez un mois</mat-option>
                        <mat-option value="Janvier">Janvier</mat-option>
                        <mat-option value="Février">Février</mat-option>
                        <mat-option value="Mars">Mars</mat-option>
                        <mat-option value="Avril">Avril</mat-option>
                        <mat-option value="Mai">Mai</mat-option>
                        <mat-option value="Juin">Juin</mat-option>
                        <mat-option value="Juillet">Juillet</mat-option>
                        <mat-option value="Août">Août</mat-option>
                        <mat-option value="Septembre">Septembre</mat-option>
                        <mat-option value="Octobre">Octobre</mat-option>
                        <mat-option value="Novembre">Novembre</mat-option>
                        <mat-option value="Décembre">Décembre</mat-option>
                        <mat-option value="Inconnu">Mois non renseigné</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <mat-form-field appearance="outline">
            <mat-label>Répertoire de base</mat-label>
            <!--<ngx-mat-file-input formControlName="directory"></ngx-mat-file-input>-->
            <!--<input hidden (change)="onFileSelected()" formControlName="fileInput" #fileInput type="file" id="file">-->
            <textarea formControlName="directory" matInput placeholder="Répertoire de base"></textarea>
            <!--<input type="file" id="monChemin" formControlName="directory" matInput onchange="filePath()">
        <input hidden (change)="selectFile($event)" formControlName="fileInput" #fileInput type="file">-->
        <mat-error>Ce champ est requis</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Spécificité</mat-label>
            <textarea formControlName="specificite" matInput placeholder="Spécificité"></textarea>
        </mat-form-field>
        <div class="row mt-3" *ngIf="noVisibleFieldFolder===false">
            <div class="col-12">
                <mat-radio-group class="mb-5">
                    <mat-label>Nom du (des) dossier(s) :</mat-label><br>
                    <mat-radio-button [value]="0" class="example-margin" (change)="onChange($event)" class="mb-4">
                        Mois
                    </mat-radio-button>
                    <mat-radio-button [value]="1" class="example-margin" (change)="onChange($event)">
                        Lettres
                    </mat-radio-button>
                    <mat-radio-button [value]="2" class="example-margin" (change)="onChange($event)">
                        Nom personnalisé
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="selectedValue === 0"> <!--Month-->
                   
                    <!--<mat-label>Les mois: </mat-label><br>-->
                        <ul formArrayName="listMonthLetter">
                            <li *ngFor="let month of listMonthLetter; let i = index"  class="list-style-none mr-10">
                                <span *ngIf="month.type==='Month'">
                                    <input type="checkbox" [formControlName]="i">{{month.value}}
                                </span>
                            </li>
                        </ul>
                </div>
                <div *ngIf="selectedValue === 1">  <!-- Letter-->
                
                    <!--<mat-label>Les lettres: </mat-label><br>-->
                        <ul>
                            <li *ngFor="let month of listMonthLetter; let i = index" formArrayName="listMonthLetter" class="list-style-none mr-10">
                                <span *ngIf="month.type==='Letter'">
                                <input type="checkbox" [formControlName]="i">{{month.value}}</span>
                            </li>
                        </ul>
                </div> 
                <div *ngIf="selectedValue === 2">  <!-- Custom name-->
                
                <mat-label>Nom personalisé : </mat-label><br>
                
                    <!--<div class="row mt-3">
                        <div class="col-12">
                        <div *ngFor="let item of folders">
                            <mat-form-field appearance="outline">
                                <mat-label>Dossier:</mat-label>
                                <textarea matInput >{{item.folder}}</textarea>
                            </mat-form-field>
                        </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                        <div *ngFor="let item of folders">
                            <mat-form-field appearance="outline">
                                <mat-label>Dossier:</mat-label>
                                <textarea formControlName="folder" matInput ></textarea>
                                <p></p>
                            </mat-form-field>
                        </div>

                        </div>
                    </div>-->
                    <!--<div class="row">

                        <div class="col-12">
                            <div formArrayName="title">
                                <div *ngFor="let item of title.controls; let folderIndex=index" [formGroupName]="folderIndex">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nom du dossier:</mat-label>
                                        <textarea formControlName="folder" matInput placeholder="Dossier"></textarea>
                                    </mat-form-field>
                                    <button class="mb-3" mat-raised-button color="warn" (click)="deleteSellingFolder(folderIndex)">
                                        <span class="mat-button-wrapper">
                                            <mat-icon _ngcontent-bsp-c180="" role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon>
                                        </span>
                                    </button>
                                </div>
                        <button mat-raised-button color="primary" (click)="addSellingFolder()" class="mt-3">Ajouter nouveau dossier</button>
                            </div>
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col-12">
                            <div formArrayName="selling_folders">
                            <div *ngFor="let item of sellingFolders.controls; let folderIndex=index" [formGroupName]="folderIndex">
                                <mat-form-field appearance="outline">
                                    <mat-label>Dossier:</mat-label>
                                    <textarea formControlName="folderCustom" matInput placeholder="Dossier"></textarea>
                                </mat-form-field>
                                <button class="mb-5" mat-raised-button color="warn" (click)="deleteSellingFolder(folderIndex)">X</button>
                            </div>
                            <button mat-raised-button color="primary" (click)="addSellingFolder()">Ajouter nouveau dossier</button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <div *ngFor="let item of folders">
                                <mat-form-field appearance="outline">
                                    <!--<span>Nombre de dossiers : {{folders.length}}</span>-->
                                    <mat-label>Dossier:</mat-label>
                                    <textarea matInput >{{ item.folderCustom }}</textarea>
                                    <p></p>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="row mt-3">
             <div class="col-12">
                <mat-radio-group formControlName="state">
                    <mat-label>État du classeur :</mat-label><br>
                    <mat-radio-button 
                    *ngFor="let etat of etats" [value]="etat"
                     class="example-margin" (change)="onChangeState($event)">{{etat}}
                    </mat-radio-button>
                </mat-radio-group>
             </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Commentaire:</mat-label>
                    <textarea formControlName="comment" matInput ></textarea>
                </mat-form-field>
             </div>
        </div>
    </form>
</div>
<div mat-dialog-actions [align]="'end'">
    <button class="ml-8" mat-raised-button color="primary" (click)="addFolderNext()">{{addNextBtn}}</button>
    <button class="ml-8" mat-raised-button color="primary" (click)="addFolder()">{{actionBtn}}</button>
    <button mat-raised-button color="warn" mat-dialog-close>Fermer</button>
</div>